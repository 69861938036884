@import "colors";

footer {
  background-color:$first;
  height: 40px;
  width: 100%;
 padding: 3rem;
  color: $dark;
  display: flex;
  justify-content: space-between;
//   grid-template-columns: 4fr 1fr;

  > div {
    display: flex;
   // background-color:blueviolet ;
    flex-direction: column;
    justify-content: center;
    > h1 {
      font: 300 2rem $text1;
      text-transform: uppercase;
      color: $third;
    }
    > p {
      font: 500 1.2rem $text1;
    }
    > h5 {
      font: 300 1.7rem $text1;
      margin: 0.3rem 0;
      text-align: center;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        display: flex;
        // background-color: aqua;

        align-items: center;
        justify-content: space-around;
        > a {
          color: $dark;
          margin: 1rem;
          font: 900 1rem $text2;
          &:hover {
            color: $third;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
footer{
>div{
  >h1{
    font-size: x-large;
  }
}
}
}
