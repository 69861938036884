$first:white;
$second:#090c31;
$third:#5853ff;
$dark: rgb(44,44,44);

// $light_blue:#D7D9E2;
 $light_blue:#dee2e6;
$dark_blue:#1B3766;
$white:#FFFFFF;
$black:#000000;

$gray:#4D4C4C;
$red:#F11E1E;
$text1: "Montserrat";
$text2:cursive;
