@import "colors";

nav {
    width : 100%;
    padding : 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: $first;
    top: 0%;
    z-index: 10;
     > h1 {
        text-transform: uppercase;
     }
     > main {
        width: 70%;
        display: flex;
        justify-content: flex-end;
         >div{
            display: flex;
            background-color: $first;

            align-items: center;

         }
         > a {
         color : $dark;
         margin : 1rem;
         font-size: large;
          &:hover{
            color: $third;
          }
      }
     }
      

}