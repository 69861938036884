@import "colors";

.PharmacyContainer{
    width: 100%;

     min-height: 80vh;
     background-color: $white;
    // background-color: rosybrown;
    display: flex;
    
   
   
    align-items: center;
    justify-content: center;
    flex-direction: column;

    >h1 {
        text-align: center;
        font-weight: 400;
        // font-size:50px ;
        font-family: $text2;
        color: $dark_blue;
        margin: 3rem;
    }

    >p {
        // margin: 0.5rem 0;
        width: 100%;
        ;
        text-align: center;
        font: 400 1 $text1 ;

        >span {
            color: $second;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    >div {
        display: flex;
        width:80vw;
    background-color: $light_blue;
    margin: 1rem;
    padding: 1rem;
   
        >div:nth-child(1){
        display: flex;
        flex-direction: row;
        // justify-content: space-evenly;
         width: 70%;
        // background-color: beige;
        >img{
            max-width: 100%;
            height: auto;
        }
        >div{
            display: flex;
            flex-direction: column;

           
            // background-color: cadetblue;
            padding:1rem;
           
            // margin:1rem;
           
        }
     
       
    }
    >div:nth-child(2){
        width: 30%;
        display: flex;
        flex-direction: column;
        padding:1rem;
        // width: 90vw;
    //  background-color: aqua;
     justify-content: space-between;
     >p{
        text-align: center;
         margin: 0.5rem;
        padding: 0.7rem;
        border: none;
        border-radius: 5px;
        background-color: $dark_blue;
        color: $first;
        width: 200px;
        font: 500 1rem $text1;

        &:hover {
            opacity: 0.5;
        }
     }
       
        // margin:1rem;
       
    }
        
    }
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0,0,0,0.1);
    // background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: transparent; 
    padding: 20px;
    border-radius: 8px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out; /* Fade-in animation */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

@media screen and (max-width: 900px) {
    .PharmacyContainer {
        >h1 {
        text-align: center;
        font-weight: 400;
         font-size:large ;
        font-family: $text2;
        color: $dark_blue;
        margin: 3rem;
    }
        > div {
        display: flex;
        flex-direction: column;
        // justify-content: space-evenly;
         width: 95%;
        // background-color: beige;
        
        >div{
            display: flex;
            flex-direction: column;

           
            // background-color: cadetblue;
            padding:1rem;
           
            // margin:1rem;
           
        }}
     
       
    }
    .PharmacyContainer > div >div:nth-child(2){
        width: 100%;
        display: flex;
        flex-direction: row;
        // padding:1rem;
        // width: 90vw;
    //  background-color: aqua;
     justify-content: space-between;
     >p{
        //  margin: 0.5rem;
        // padding: 0.7rem;
        border: none;
        border-radius: 5px;
        background-color: $dark_blue;
        color: $first;
        width: 200px;
        font: 500 1rem $text1;

        &:hover {
            opacity: 0.5;
        }
     }
       
        // margin:1rem;
       
    }
    
    
}

