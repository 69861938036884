@import "colors.scss";

.BillsContainer{
    width: 100%;

    // height: 80vh;
     background-color: $white;
    // background-color: rosybrown;
    display: flex;
    
   
   
    align-items: center;
    justify-content: center;
    flex-direction: column;
    >h1 {
        text-align: center;
        font-weight: 400;
        // font-size:50px ;
        font-family: $text2;
        color: $dark_blue;
        margin: 3rem;
    }
   
      
}

@media screen and (max-width: 700px){
  .BillsContainer {
    >h1{
      text-align: center;
        font-weight: 400;
         font-size:larger ;
        font-family: $text2;
        color: $dark_blue;
        margin: 3rem;
    }
  }
}
.table-container {
    overflow-x: auto;
    width: 100%;
  }
  
.table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  /* Table row hover effect */
  .table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  /* Table cell alignment */
  .table td {
    vertical-align: middle;
    font-size: medium;
  }
  .table td a {
    // text-decoration: underline;
    color:$gray;
    &:hover{
        color:$dark_blue;
    }
  }
  
  /* Table actions */
  .table-action a {
    color: #007bff;
    text-decoration: none;
  }
  
  .table-action a:hover {
    text-decoration: underline;
  }