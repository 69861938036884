@import "colors";

.regContainer {
    width : 100vw;
    // height: 0vh;
    background-color: $light_blue;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: auto;
    
    >h1{
        text-align: center;
            font-weight: 400;
            // font-size:50px ;
            font-family :  $text2; 
            color: $dark_blue;
            margin: 3rem;
    }
    >p{
        // margin: 0.5rem 0;
               width: 100%;;
                text-align: center;
                font : 400 1 $text1 ;
             
                >span{
                    color: $second;
                    cursor: pointer;
                    text-decoration: underline;
                }
    }
    >div{
        width:100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        > img {
            // background-color: antiquewhite;
            width :40vw;
            height: auto;
            animation: imgUp 0.7s linear infinite alternate ;
        }
        > form{
            width:40vw;
            margin :5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            > div{
                flex-direction: column;
                // background-color: azure;
               height: 4.5rem;
               display: flex;
               align-items: center;
               justify-content: flex-end;
               width: 100%;
               margin :0.5rem;
               > label {
                   font : 400 1rem $text1;
                   width: 100%;
                   margin: 0.3rem 1rem;
                   text-align: left;
                   >span{
                    color: $red;
                    font-size: 1rem;
                   }
               }
               > input {
                   align-self:stretch ;
                   width: 100%;
                   padding: 1rem;
                   border-radius: 5px;
                   border: 1px solid #ccc;
                   outline: none;
    
               }
              
               
             
               
            }

            >div:nth-child(5){
               
                    // width: 30vw;
                    flex-direction: column;
                display:   flex;
                //  background-color: antiquewhite;
                 height: 4.5rem;
               display: flex;
               align-items: center;
               justify-content: flex-end;
               width: 100%;
               margin :0.5rem;
               
                > label {
                    font : 400 1rem $text1;
                    width: 100%;
                    margin: 0.3rem 1rem;
                    text-align: left;
                    >span{
                     color: $red;
                     font-size: 1rem;
                    }
                }
                >div{
                    display: flex;
                    flex-direction: row;
                    //  background-color: azure;
                    width:100%;
                    align-items: flex-start;
                    // justify-content: space-between;
                }
                
             
             }
           
             > button {
                margin-top :3rem ;
                 padding: 0.7rem;
                 border: none;
                  border-radius: 5px;
                  background-color: $dark_blue;
                  color: $first;
                  width: 200px;
                  font: 500 1rem $text1;
                  &:hover{
               opacity :0.5;
                  }
    
             }
             >p {
                text-align: center;
                margin-top: 1rem;
                color: $dark_blue;
                font: 1em sans-serif;
                width:100%;
                cursor: pointer;
             }
       }
    }

}

.regContainer::-webkit-scrollbar {
  display: none;
}


@keyframes imgUp {
    to {
        transform: translateY(-10px);
    }
}

@media screen and (max-width: 700px) {
    .regContainer {
        width: 100vw;
        >h1{
            // font-size: large;
        text-align: center;
            font-weight: 400;
            // font-size:50px ;
            font-family :  $text2; 
            color: $dark_blue;
            margin: 3rem;
            >span{
                display: none;
            }
    }
    >p{
        // margin: 0.5rem 0;
               width: 100%;;
                text-align: center;
                font : 400 1 $text1 ;
                font-size: smaller;
             
                >span{
                    color: $second;
                    cursor: pointer;
                    text-decoration: underline;
                }
    }

         > div 
    > img {
        display: none;
    }

    }
    
    
   
    .regContainer > div > form {
        width: 100vw;
        // background-color: pink;
        >div{
            width: 80vw;
        }
    }
}