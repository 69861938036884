@import "colors";

.uploadRxConatiner {
    width: 100%;
    min-height:90vh;
    // height: 80vh;
     background-color: $light_blue;
    // background-color: rosybrown;
    display: flex;
   
   
    align-items: center;
    justify-content: center;
    flex-direction: column;

    >h1 {
        text-align: center;
        font-weight: 400;
        // font-size:50px ;
        font-family: $text2;
        color: $dark_blue;
        margin: 3rem;
    }

    >p {
        // margin: 0.5rem 0;
        width: 100%;
        ;
        text-align: center;
        font: 400 1 $text1 ;

        >span {
            color: $second;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    >form {
        // margin: 5rem;
        display: flex;
        flex:1; 
        align-self: center;

        flex-direction: row;
         align-items: center;
        justify-content: center;
    //  background-color: aqua;

        >div {
            display: flex;
            // background-color: antiquewhite;
           
        
            flex-wrap: wrap;
            // justify-content: space-between;
         
          
            >div {
                flex-direction: column;
                display: flex;
                width: 150px;
                // height : 100px;
            //   background-color: antiquewhite;
                
                // width: fit-content;
           background-color: rgba(175, 213, 254, 0.4);
                // border-radius: 20px;
                // position: relative;
                // margin: 40px auto 0;
                border-radius: 10px;
                 padding: 24px;
                align-items: center;
                justify-content: center;
                margin: 1rem;
               

                >div {
                        display: flex;
                        width:100%;
                      
                       
                         
                          align-items: "center";
                          justify-content: "space-between";
                }

                >button {
                    box-sizing: content-box;
                    width: 1em;
                    height: 1em;
                    padding: 0.25em 0.25em;
                    color: #000;
                    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
                    border: 0;
                    border-radius: 0.25rem;
                    opacity: 0.5;

                    &:hover {
                        color: #000;
                        text-decoration: none;
                        opacity: 0.75;
                    }
                }
                >img{
                    max-width: 100%;
                    height: auto;
                }
            }

        }




        >button {
            margin-top: 3rem;
            padding: 0.7rem;
            border: none;
            border-radius: 5px;
            background-color: $dark_blue;
            color: $first;
            width: 200px;
            font: 500 1rem $text1;

            &:hover {
                opacity: 0.5;
            }

        }

        >p {
            text-align: center;
            margin-top: 1rem;
            color: $dark_blue;
            font: 1em sans-serif;
            width: 100%;
            cursor: pointer;
        }
    }
}

.profile-pic-upload {
    width: 150px;
    background-color: rgba(175, 213, 254, 0.4);
    border-radius: 20px;
    position: relative;
    margin: 40px auto 0;
    padding: 24px;
}

.profile-pic-upload input[type=file] {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}

.profile-pic-upload .cam-col {
    width: 70px;
    height: 70px;
    background-color: #ffffff;
    border-radius: 70px;
    overflow: hidden;
    margin: 0 auto;
}

.profile-pic-upload span {
    color: #000000;
    font-size: 13px;
    list-style: none;
    display: inline-block;
    margin-top: 15px;
}
.imageFluid{
    max-width: 100%;
    height: auto;
}
.button-primary{
    margin :3rem ;
    padding: 0.7rem;
    border: none;
     border-radius: 5px;
     background-color: $dark_blue;
     color: $first;
     width: 200px;
     font: 500 1rem $text1;
     &:hover{
  opacity :0.5;
}}

@media screen and (max-height: 900px){
    .uploadRxConatiner{
        height: 90vh;
        >h1 {
        text-align: center;
        font-weight: 400;
         font-size:large ;
        font-family: $text2;
        color: $dark_blue;
        margin: 3rem;
    }
    }
}